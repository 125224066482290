import ARBITRUM_NETWORK from "../../assets/network-logos/Arbitrum-Badge.svg";
import ETH_NETWORK from "../../assets/network-logos/Ethereum-Badge.svg";
import OPTIMISM_NETWORK from "../../assets/network-logos/Optimism-Badge.svg";
import POLYGON_NETWORK from "../../assets/network-logos/Polygon-Badge.svg";
import SOL_NETWORK from "../../assets/network-logos/Solana-Badge.svg";
import BTC_NETWORK from "../../assets/network-logos/Bitcoin-Badge.svg";
import BASE_NETWORK from "../../assets/network-logos/Base-Badge.svg";
import { Button } from "../Button";
import { FiatInstrument } from "../FiatInstrument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { getFiatDisplayValue, getFiatType } from "../../utils/fiatInstrument";
import {
  TransferKind,
  FiatInstrument as FiatInstrumentT,
  FiatInstruments,
  SingleUseInstrument,
} from "../../generated/schema";
import { Logo } from "../Logo";
import { Network } from "../../types";
import { Text } from "../Text";
import { WalletInstrument } from "../WalletInstrument";
import { brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useImpressionTracker } from "../../hooks/useImpressionTracker";
import { MesoKitContext } from "../../MesoKitContext";

const NetworkBadge = ({ network }: { network: Network }) => {
  const networkBadges = {
    [Network.ETHEREUM_MAINNET]: ETH_NETWORK,
    [Network.SOLANA_MAINNET]: SOL_NETWORK,
    [Network.ARBITRUM_MAINNET]: ARBITRUM_NETWORK,
    [Network.OP_MAINNET]: OPTIMISM_NETWORK,
    [Network.POLYGON_MAINNET]: POLYGON_NETWORK,
    [Network.BITCOIN_MAINNET]: BTC_NETWORK,
    [Network.BASE_MAINNET]: BASE_NETWORK,
  };

  return (
    <div
      data-testid={`${network}NetworkBadges`}
      className="size-9 bg-neutral-600 dark:bg-neutral-200"
      style={{
        WebkitMask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
        mask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
      }}
    />
  );
};

const ApplePayTooltip = ({
  setFiatInstrument,
}: {
  setFiatInstrument: (
    instrument: FiatInstrumentT | SingleUseInstrument,
  ) => void;
}) => {
  const { amplitude } = useContext(MesoKitContext);
  const { getImpressionCount, trackImpression } = useImpressionTracker();
  const impressionCount = useRef(getImpressionCount("applePayTooltip"));

  useEffect(() => {
    if (impressionCount.current === 0) {
      trackImpression("applePayTooltip");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return impressionCount.current >= 1 ? null : (
    <motion.div
      // This is scale in small from transfer-app animation.ts
      initial={{ opacity: 0, scale: 0.95, y: 8, zIndex: 50 }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      exit={{ opacity: 0, scale: 0.95, y: 8 }}
      data-testid="ApplePayTooltip"
      className="absolute top-10 z-50 w-[21rem] rounded-2xl border border-neutral-200 bg-white p-3 text-sm shadow-xl dark:border-neutral-700 dark:bg-neutral-800"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon
            data-testid="FiatInstrumentIcon"
            size="xl"
            icon={brands("cc-apple-pay")}
          />
          <Text className="font-semibold leading-tight">
            Now accepting Apple Pay!
          </Text>
        </div>
        <Button
          className="h-8 rounded-xl px-4 text-xs font-bold"
          onClick={() => {
            amplitude?.track("Apple Pay Tooltip Clicked");
            setFiatInstrument(SingleUseInstrument.APPLE_PAY);
          }}
        >
          Try it out
        </Button>
      </div>
      <div className="absolute left-24 top-[-1px] size-2 -translate-x-2/3 -translate-y-1/2 -rotate-45 transform border-r border-t border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-800"></div>
    </motion.div>
  );
};

const ProfileDetails = ({
  walletAddress,
  fiatInstrument,
  fiatInstruments,
  setFiatInstrument,
  depositAddress,
  transferKind,
  network,
  applePayEnabled = false,
  canShowPopover = true,
  disableDepositAddressPopover = false,
}: {
  walletAddress: string;
  fiatInstrument?: FiatInstrumentT | SingleUseInstrument;
  fiatInstruments?: FiatInstruments;
  setFiatInstrument?: (
    instrument: FiatInstrumentT | SingleUseInstrument,
  ) => void;
  depositAddress?: string;
  transferKind: TransferKind;
  network: Network;
  applePayEnabled?: boolean;
  canShowPopover?: boolean;
  disableDepositAddressPopover?: boolean;
}) => {
  const { amplitude } = useContext(MesoKitContext);
  const [showPopover, setShowPopover] = useState<
    "wallet" | "fiat" | "depositAddress"
  >();
  const networkName = (Object.entries(Network).find(
    ([_, v]) => v === network,
  ) || [""])[0]
    .split("_")
    .map((name) => name.charAt(0).toUpperCase() + name.substr(1).toLowerCase())
    .join(" ");
  const isCashIn = transferKind === TransferKind.CASH_IN;

  const sourceClassName =
    "flex h-7 flex-auto items-center gap-1 font-bold outline-none ring-0";
  const destinationClassName = canShowPopover
    ? "profile-capsule-inner font-bold group cursor-pointer rounded-full bg-transparent py-1 px-3 h-full"
    : "profile-capsule-inner font-bold rounded-full bg-transparent py-1 pl-1 pr-3";
  const popoverClassName = twMerge(
    "absolute z-20 mt-9 flex w-80 flex-col justify-center items-center gap-1 rounded-[16px] border border-transparent p-4 shadow-xl network-popover",
  );

  const fiatDetails = (
    <Popover className="relative">
      <PopoverButton
        as={Fragment}
        data-testid="FiatDetails"
        disabled={!canShowPopover}
      >
        <div
          className={twMerge(
            "flex items-center gap-2",
            canShowPopover
              ? "cursor-pointer transition-transform active:scale-95"
              : "",
          )}
          onClick={() =>
            canShowPopover &&
            setShowPopover(showPopover === "fiat" ? undefined : "fiat")
          }
        >
          <FiatInstrument
            className={isCashIn ? sourceClassName : destinationClassName}
            cardScheme={getFiatType(fiatInstrument)}
            display={getFiatDisplayValue(fiatInstrument)}
          />
          {canShowPopover && (
            <div className="capsule-popover-button">
              <FontAwesomeIcon
                icon={icon({ name: "chevron-down", style: "solid" })}
              />
            </div>
          )}
        </div>
      </PopoverButton>
      {canShowPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "fiat"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {fiatInstrument ? (
            <PopoverPanel
              data-testid="FiatPopover"
              anchor="bottom"
              className="network-popover z-20 flex-col items-center gap-1 rounded-[16px] border p-2 text-xs font-semibold text-black shadow-lg dark:border-neutral-700 dark:text-white"
            >
              <div className="w-48 border-b p-3 font-bold dark:border-b-neutral-600">
                Payment Methods
              </div>
              <div className="pt-2">
                {fiatInstruments &&
                  fiatInstruments.collection.map((fi) => {
                    return (
                      <div
                        key={`popover-${fi.id}`}
                        className="flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
                        onClick={() => {
                          amplitude?.track("Fiat Instrument Selector Clicked", {
                            fiatType: getFiatType(fi),
                          });
                          setFiatInstrument && setFiatInstrument(fi);
                          setShowPopover(undefined);
                        }}
                      >
                        <FiatInstrument
                          cardScheme={getFiatType(fi)}
                          display={getFiatDisplayValue(fi)}
                        />
                        {fiatInstrument &&
                          typeof fiatInstrument === "object" &&
                          fi.id === fiatInstrument.id && (
                            <FontAwesomeIcon
                              icon={icon({ name: "check-circle" })}
                              className="text-primary dark:text-primary-light"
                              size="lg"
                            />
                          )}
                      </div>
                    );
                  })}
              </div>
              {applePayEnabled && (
                <div
                  onClick={() => {
                    amplitude?.track("Fiat Instrument Selector Clicked", {
                      fiatType: getFiatType(SingleUseInstrument.APPLE_PAY),
                    });
                    setFiatInstrument &&
                      setFiatInstrument(SingleUseInstrument.APPLE_PAY);
                    setShowPopover(undefined);
                  }}
                  className="flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
                >
                  <FiatInstrument
                    cardScheme={getFiatType(SingleUseInstrument.APPLE_PAY)}
                    display={getFiatDisplayValue(SingleUseInstrument.APPLE_PAY)}
                  />
                  {fiatInstrument === SingleUseInstrument.APPLE_PAY && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check-circle" })}
                      className="text-primary"
                      size="lg"
                    />
                  )}
                </div>
              )}
            </PopoverPanel>
          ) : (
            <PopoverPanel
              data-testid="FiatPopover"
              anchor="bottom"
              className="network-popover mt-1 flex items-center gap-2 rounded-[16px] border border-transparent p-4 shadow-xl"
            >
              <FontAwesomeIcon
                icon={icon({ name: "credit-card", style: "solid" })}
                className="text-neutral-800 dark:text-white"
              />
              <div className="flex flex-col">
                <Text className="text-xs font-semibold">
                  Log in to see your payment method
                </Text>
              </div>
            </PopoverPanel>
          )}
        </Transition>
      )}
    </Popover>
  );

  const walletDetails = (
    <div
      className={isCashIn ? destinationClassName : sourceClassName}
      data-testid="WalletDetails"
      onClick={() =>
        canShowPopover &&
        setShowPopover(showPopover === "wallet" ? undefined : "wallet")
      }
    >
      <WalletInstrument
        network={network}
        walletAddress={walletAddress}
        className="font-bold group-active:scale-95"
      />
    </div>
  );

  return (
    <motion.div
      layout
      data-testid="ProfileDetails"
      className="relative flex flex-col items-center gap-2"
    >
      <div className="capsule-container">
        <div className="capsule-source">
          {isCashIn ? fiatDetails : walletDetails}
        </div>

        {!isCashIn && (
          <div
            onClick={() =>
              canShowPopover &&
              !disableDepositAddressPopover &&
              setShowPopover(
                showPopover === "depositAddress" ? undefined : "depositAddress",
              )
            }
            data-testid="DepositAddress"
            className="flex items-center pl-1 pr-2"
          >
            <Logo size="xs" showText={false} />
          </div>
        )}
        {!isCashIn && (
          <motion.div
            animate={{
              opacity: [0, 0.5, 0],
              x: [-12, 0, 0],
              transition: {
                delay: 1,
                repeatDelay: 2,
                ease: "easeInOut",
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              },
            }}
          >
            <FontAwesomeIcon
              icon={icon({ name: "chevron-right", style: "regular" })}
            />
          </motion.div>
        )}
        <div className="capsule-destination">
          {isCashIn ? walletDetails : fiatDetails}
        </div>
      </div>

      <AnimatePresence>
        {setFiatInstrument &&
          fiatInstrument &&
          fiatInstrument !== SingleUseInstrument.APPLE_PAY &&
          applePayEnabled &&
          canShowPopover &&
          !showPopover && (
            <ApplePayTooltip setFiatInstrument={setFiatInstrument} />
          )}
      </AnimatePresence>
      {canShowPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "wallet"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="WalletPopover" className={popoverClassName}>
            <NetworkBadge network={network} />
            <div className="flex flex-col">
              <Text className="text-center text-sm font-semibold">
                {networkName}
              </Text>
              <Text className="whitespace-normal break-all text-center font-mono text-[10px] font-bold">
                {walletAddress}
              </Text>
            </div>
          </div>
        </Transition>
      )}
      {canShowPopover && !disableDepositAddressPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "depositAddress"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="DepositAddressPopover" className={popoverClassName}>
            <Logo size="sm" showText={false} />
            <div className="flex flex-col">
              <Text className="text-sm font-semibold">
                Meso Deposit Address
              </Text>
              {fiatInstrument ? (
                <div className="flex items-center gap-1">
                  {!depositAddress && (
                    <FontAwesomeIcon
                      icon={icon({ name: "loader" })}
                      size="xs"
                      className="fa-spin"
                    />
                  )}
                  <Text
                    className={twMerge(
                      "whitespace-normal break-words text-[10px]",
                      depositAddress && "font-mono font-bold",
                    )}
                  >
                    {depositAddress ??
                      "Creating deposit address. This may take a minute..."}
                  </Text>
                </div>
              ) : (
                <Text className="whitespace-normal break-words text-[10px] font-bold">
                  Log in to see your deposit address
                </Text>
              )}
            </div>
          </div>
        </Transition>
      )}
    </motion.div>
  );
};

export { ProfileDetails };
