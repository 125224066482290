import { OnboardingContextValue, UserStatus } from "../types";
import { Button, Title, Text, LoadingInterstitial } from "@tigris/mesokit";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnimatePresence, motion } from "framer-motion";
import { motionVariants } from "../utils/animation";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { useRouter } from "../hooks/useRouter";

const UnderReview = ({
  onReturnToTransfer,
}: {
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
}) => (
  <motion.form
    {...motionVariants.fadeIn}
    name="ManualReview"
    id="ManualReview"
    data-testid="ManualReview"
    onSubmit={(event: FormEvent) => {
      event.preventDefault();
      onReturnToTransfer("onboardingTerminated");
    }}
    className="flex h-full flex-col gap-2 pt-8"
  >
    <Title.Medium bold>Account Under Review</Title.Medium>
    <Text>
      Your account is currently under review. We will contact you shortly with
      the next steps. If you have any questions please visit our{" "}
      <a
        href="https://support.meso.network/"
        target="_blank"
        className="underline transition-opacity hover:opacity-90"
        rel="noreferrer"
      >
        support center
      </a>
      .
    </Text>
    {
      <div className="mt-auto">
        <Button
          key="ManualReview:button"
          type="submit"
          data-testid="ManualReview:closeButton"
        >
          Close
        </Button>
      </div>
    }
  </motion.form>
);

const DocumentVerification = ({
  identityDocumentUrl,
}: {
  identityDocumentUrl: string;
}) => (
  <motion.div className="flex h-full flex-col" {...motionVariants.fadeIn}>
    <div className="mt-4">
      <Title.Medium bold>Document Verification</Title.Medium>
      <Text>
        Unfortunately, we could not verify your identity with the details you
        provided and your account is under review.
      </Text>
    </div>

    <Text className="mt-2">
      Use the button below to upload additional documentation and we will get
      back to you with a decision as soon as we can.
    </Text>
    <div className="mt-auto">
      <Button
        as="a"
        href={identityDocumentUrl}
        target="_blank"
        rel="noopener noreferrer"
        key="OnboardingDocumentUpload:button"
        data-testid="OnboardingDocumentUpload:uploadLink"
      >
        Upload Documents
        <FontAwesomeIcon
          icon={icon({ name: "arrow-up-right-from-square", style: "solid" })}
          className="ml-2"
        />
      </Button>
    </div>
  </motion.div>
);

export const ManualReview = () => {
  const {
    api: { resolveIdentityDocumentUrl },
  } = useApi();
  const { user, returnToTransfer } = useOnboarding();
  const { disableNavigation } = useRouter();
  const isManualReview = useMemo(
    () => user.activation?.userStatus === UserStatus.IN_REVIEW,
    [user.activation?.userStatus],
  );

  useEffect(() => disableNavigation(), [disableNavigation]);

  const [isLoading, setIsLoading] = useState(isManualReview);
  const [identityDocumentUrl, setIdentityDocumentUrl] = useState<string | null>(
    null,
  );
  const [hasIdentityDocumentUrlError, setHasIdentityDocumentUrlError] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (isManualReview) {
        const identityDocumentUrlResult = await resolveIdentityDocumentUrl();

        if (identityDocumentUrlResult.isErr()) {
          setHasIdentityDocumentUrlError(true);
        } else {
          setIdentityDocumentUrl(identityDocumentUrlResult.value.url);
        }

        // UI buffer to allow the loading transition to occur
        setTimeout(() => setIsLoading(false), 200);
      }
    })();
  }, [isManualReview, resolveIdentityDocumentUrl]);

  const renderDocumentVerification = useMemo(() => {
    return (
      !hasIdentityDocumentUrlError && isManualReview && identityDocumentUrl
    );
  }, [hasIdentityDocumentUrlError, identityDocumentUrl, isManualReview]);

  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <LoadingInterstitial mode="waiting" />
      ) : renderDocumentVerification ? (
        <DocumentVerification identityDocumentUrl={identityDocumentUrl!} />
      ) : (
        <UnderReview onReturnToTransfer={returnToTransfer} />
      )}
    </AnimatePresence>
  );
};
