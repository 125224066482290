import { AnimatePresence, motion } from "framer-motion";
import { Asset, Network, UserLimits } from "../../types";
import { AssetAmount } from "./AssetAmount";
import { Hero } from "./Hero";
import {
  FiatInstrument,
  FiatInstruments,
  TransferKind,
  SingleUseInstrument,
} from "../../generated/schema";
import { ThemeName } from "../../style/themes";
import { ProfileDetails } from "./ProfileDetails";
import { BuyLimit } from "../BuyLimit";
import { useMemo } from "react";

export const CardHero = ({
  theme,
  asset,
  amount,
  network,
  walletAddress,
  fiatInstrument,
  fiatInstruments,
  setFiatInstrument,
  depositAddress,
  transferKind,
  canShowPopover = true,
  applePayEnabled = false,
  editable = false,
  onEditAmount = () => {},
  limits,
  disabled,
}: {
  theme?: ThemeName;
  asset?: Asset;
  amount?: string;
  network: Network;
  walletAddress: string;
  fiatInstrument?: FiatInstrument | SingleUseInstrument;
  fiatInstruments?: FiatInstruments;
  setFiatInstrument?: (
    instrument: FiatInstrument | SingleUseInstrument,
  ) => void;
  depositAddress?: string;
  transferKind: TransferKind;
  canShowPopover?: boolean;
  applePayEnabled?: boolean;
  editable?: boolean;
  onEditAmount?: () => void;
  limits?: UserLimits;
  disabled?: boolean;
}) => {
  const amountClassName = useMemo(() => {
    if (editable) {
      return "-my-1.5 rounded-xl px-4 py-1.5 transition-all hover:-translate-y-1 hover:bg-neutral-800/5 dark:hover:bg-white/5";
    } else {
      return "cursor-default";
    }
  }, [editable]);

  return (
    <AnimatePresence mode="sync">
      <div
        data-testid="CardHero"
        className={
          disabled ? "pointer-events-none cursor-not-allowed opacity-50" : ""
        }
      >
        <Hero theme={theme}>
          <button onClick={onEditAmount} className={amountClassName}>
            <AssetAmount asset={asset!} amount={amount} />
          </button>
          <ProfileDetails
            walletAddress={walletAddress}
            fiatInstrument={fiatInstrument}
            fiatInstruments={fiatInstruments}
            setFiatInstrument={setFiatInstrument}
            depositAddress={depositAddress}
            transferKind={transferKind}
            network={network}
            applePayEnabled={applePayEnabled}
            canShowPopover={canShowPopover}
          />

          <AnimatePresence>
            {limits && (
              <motion.div
                initial={{ height: "0" }}
                animate={{ height: "auto" }}
                className="w-full px-4"
              >
                <BuyLimit limits={limits} collapsed />
              </motion.div>
            )}
          </AnimatePresence>
        </Hero>
      </div>
    </AnimatePresence>
  );
};
