import { Routes } from "@src/utils/constants";
import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import { LoginFragment, TransferKind } from "@src/generated/sdk";
import { InternalLinkPath, UserStatus } from "@src/types";
import { NavigateOptions } from "react-router-dom";
import { Routes as OnboardingRoutes } from "@tigris/onboarding";

type ProfileStatus = LoginFragment["profileStatus"];

const onboardingSteps: Array<
  [keyof Omit<ProfileStatus, "__typename">, OnboardingRoutes]
> = [
  ["acceptedTerms", OnboardingRoutes.Root],
  ["account", OnboardingRoutes.CreateAccount],
  ["phone", OnboardingRoutes.PhoneEntry],
  ["phoneVerified", OnboardingRoutes.PhoneEntry],
  ["basicInfo", OnboardingRoutes.BasicInfoOverview],
  ["residentialAddress", OnboardingRoutes.BasicInfoOverview],
  ["taxpayerId", OnboardingRoutes.BasicInfoOverview],
  ["financialInstrument", OnboardingRoutes.AddPaymentCard],
];

type NextRoute = {
  to: InternalLinkPath;
  options?: NavigateOptions;
};

export const nextAuthStep = ({
  profileStatus,
  needsTwoFactor,
  transferKind,
  userStatus,
  search,
}: {
  profileStatus: ProfileStatus;
  needsTwoFactor: boolean;
  transferKind: TransferKind;
  userStatus?: UserStatus;
  search: string;
}): Result<NextRoute, string> => {
  const hasCompletedOnboardingSteps =
    Object.values(profileStatus).every(Boolean);

  switch (userStatus) {
    // This should be sufficient to skip looking at the `account` property and allow previously onboarded users to proceed with their transfer
    case UserStatus.ACTIVE: {
      if (needsTwoFactor) {
        return ok({ to: { pathname: Routes.TransferSheet2Fa, search } });
      } else if (transferKind === TransferKind.CASH_IN) {
        return ok({ to: { pathname: Routes.TransferSheet, search } });
      } else {
        return ok({
          to: { pathname: Routes.TransferSheetDepositAddress, search },
        });
      }
    }

    case UserStatus.NEW: {
      // If onboarding is complete, 2FA the user and then kick off activation
      if (
        hasCompletedOnboardingSteps &&
        profileStatus.phoneVerified &&
        needsTwoFactor
      ) {
        return ok({
          to: { pathname: Routes.TransferSheet2Fa, search },
          options: {
            state: {
              redirectPath: { pathname: Routes.ActivateUser, search },
            },
          },
        });
      }

      // Still need to complete onboarding
      const nextStep = onboardingSteps.find(
        ([stepName]) => !profileStatus[stepName],
      );

      let resumeOnboardingPathname: OnboardingRoutes | undefined = undefined;

      if (nextStep) {
        const step = nextStep[0];

        if (step === "basicInfo" || step === "residentialAddress") {
          resumeOnboardingPathname = OnboardingRoutes.BasicInfoEntry;
        }

        if (step === "taxpayerId") {
          resumeOnboardingPathname = OnboardingRoutes.TaxpayerIdEntry;
        }
      }

      // If onboarding is incomplete, and the user has a phone, 2FA them and then drop into onboarding
      if (
        !hasCompletedOnboardingSteps &&
        profileStatus.phoneVerified &&
        needsTwoFactor
      ) {
        return ok({
          to: { pathname: OnboardingRoutes.Phone2Fa, search },
          options: {
            state: {
              redirectPath: { pathname: resumeOnboardingPathname, search },
            },
          },
        });
      }

      return getOnboardingEntrypoint(profileStatus, search);
    }

    case UserStatus.PROVISIONAL: {
      if (hasCompletedOnboardingSteps) {
        return ok({ to: { pathname: Routes.ActivateUser, search } });
      }
    }

    case UserStatus.IN_REVIEW:
    case UserStatus.FROZEN:
    case UserStatus.DECLINED:
    case UserStatus.BLOCKED:
    case UserStatus.IN_REVIEW_BLOCKED:
    case UserStatus.UNKNOWN:
      return ok({ to: { pathname: Routes.TransferUnavailable, search } });

    default:
      return ok({ to: { pathname: OnboardingRoutes.Root, search } });
  }
};

/**
 * Determine where to drop the user in Onboarding based on their current status.
 */
export const getOnboardingEntrypoint = (
  profileStatus: ProfileStatus,
  search: string,
): Result<NextRoute, string> => {
  const next = onboardingSteps.find(([stepName]) => !profileStatus[stepName]);

  if (next) {
    return ok({ to: { pathname: next[1], search } });
  }

  return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
};
