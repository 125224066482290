import * as sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { OnboardingAppRenderContext, Routes } from "../types";
import { commonSentryConfig } from "@tigris/common";

export const Sentry = {
  init: () => {
    sentry.init({
      ...commonSentryConfig,

      integrations: [
        sentry.browserTracingIntegration(),
        sentry.replayIntegration({
          // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration/#using-a-custom-compression-worker
          workerUrl: "/worker.min.js",
        }),
      ],
    });
  },

  ErrorBoundary: sentry.ErrorBoundary,
  captureException: sentry.captureException,
  captureMessage: sentry.captureMessage,
  setContext: sentry.setContext,
  metrics: sentry.metrics,
};

export const Amplitude = {
  // Because this has to be duplicated in the `transfer-app`, we use unnecessary specificity in the naming here.
  onboardingAppContext: { renderContext: "unknown" },

  init() {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_PUBLIC_KEY, {
      appVersion: import.meta.env.VITE_TIGRIS_RELEASE,
      logLevel:
        import.meta.env.VITE_AMPLITUDE_DEBUG === "1"
          ? amplitude.Types.LogLevel.Debug
          : amplitude.Types.LogLevel.None,
      serverUrl:
        import.meta.env.VITE_AMPLITUDE_ENDPOINT ??
        "https://api2.amplitude.com/2/httpapi",
    });

    if (import.meta.env.CI === "true") {
      amplitude.setOptOut(true);
    }

    // https://www.docs.developers.amplitude.com/data/sdks/browser-2/#set-the-transport-to-use-beacon-only-when-exiting-page
    window.addEventListener("pagehide", async () => {
      amplitude.setTransport("beacon");
      amplitude.flush();
    });
  },

  setIdentifyUserId(userId: string) {
    if (userId && userId.length != 0 && !/^u_0+/.test(userId)) {
      amplitude.setUserId(userId);
    }
  },

  track: amplitude.track,

  setContext({ renderContext }: { renderContext: OnboardingAppRenderContext }) {
    // Translate our domain type to an amplitude friendly value
    let reportableRenderContext = "embeddedInTransfer";

    if (renderContext === OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER) {
      reportableRenderContext = "windowFromTransfer";
    }

    this.onboardingAppContext = { renderContext: reportableRenderContext };
  },

  // Because we don't use URL-based routing, we have to dispatch our own equivalent of a `Page Viewed` event.
  trackSyntheticOnboardingAppPageView({ pathname }: { pathname: Routes }) {
    Amplitude.track("Onboarding page view", {
      pathname,
      appName: "Onboarding App",
      renderContext: this.onboardingAppContext.renderContext,
    });
  },
};

const Telemetry = {
  init: () => {
    Sentry.init();
    Amplitude.init();
  },
};

export default Telemetry;
