import { AppContext } from "@src/contexts/AppContextProvider";
import { useRef, useEffect, useContext } from "react";
import { spring } from "../utils/animation";
import { motion } from "framer-motion";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "@src/utils/constants";
import { OutletContext } from "@src/types";
import { Amplitude } from "@src/utils/telemetry";

const APPLE_PAY_JS_API_VESION = 3;

const buttonAnimation = {
  initial: { opacity: 0, y: 24 },
  exit: { opacity: 0, y: 24, transition: spring },
  animate: { opacity: 1, y: 0, transition: { ...spring, delay: 0.25 } },
};

export const ApplePayButton = ({
  executeTransfer,
  buttonstyle = "black",
  type = "plain",
  locale = "us-EN",
}: {
  executeTransfer: (payment: ApplePayJS.ApplePayPayment) => Promise<number>;
  buttonstyle?: string;
  type?: string;
  locale?: string;
}) => {
  const {
    useQuote: { quote },
    useApplePay: { applePayInitiativeContext },
  } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    configuration: { destinationAsset },
    api: { resolveRequestApplePayPaymentSession },
  } = useContext(AppContext);
  const buttonRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!quote || !buttonRef.current) {
      return;
    }

    const currButton = buttonRef.current;
    const nextListener = (e: MouseEvent) => {
      e.stopPropagation();
      Amplitude.track("Apple Pay Button Clicked");

      const request: ApplePayJS.ApplePayPaymentRequest = {
        countryCode: "US",
        currencyCode: "USD",
        merchantCapabilities: ["supports3DS", "supportsDebit"],
        supportedNetworks: ["visa", "masterCard"],
        requiredBillingContactFields: ["name", "postalAddress"],
        requiredShippingContactFields: ["phone", "email"],
        total: {
          label: `$${quote.sourceTotal.amount} for ${quote.destination.amount} ${destinationAsset}`,
          type: "final",
          amount: quote.sourceTotal.amount,
        },
      };
      const session = new ApplePaySession(APPLE_PAY_JS_API_VESION, request);

      session.onvalidatemerchant = async () => {
        if (!applePayInitiativeContext) {
          session.abort();
          return;
        }

        const applePayPaymentSessionResult =
          await resolveRequestApplePayPaymentSession({
            input: {
              initiativeContext: applePayInitiativeContext,
            },
          });

        if (applePayPaymentSessionResult.isErr()) {
          session.abort();
        } else {
          session.completeMerchantValidation(
            applePayPaymentSessionResult.value,
          );
        }
      };

      session.onpaymentauthorized = async ({
        payment,
      }: {
        payment: ApplePayJS.ApplePayPayment;
      }) => {
        Amplitude.track("Apple Pay Payment Authorized");
        const status = await executeTransfer(payment);
        Amplitude.track("Apple Pay Payment Executed", { status });
        session.completePayment({ status });

        if (status === ApplePaySession.STATUS_SUCCESS) {
          navigate({ pathname: Routes.TransferSheetStatus, search });
        }
      };

      session.begin();
    };

    currButton.addEventListener("click", nextListener);
    return () => currButton.removeEventListener("click", nextListener);
  }, [
    applePayInitiativeContext,
    destinationAsset,
    executeTransfer,
    navigate,
    quote,
    resolveRequestApplePayPaymentSession,
    search,
  ]);

  return (
    <motion.div
      key="AnimatedApplePayButton"
      variants={buttonAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      className="max-h-12"
    >
      <apple-pay-button
        buttonstyle={buttonstyle}
        type={type}
        locale={locale}
        ref={buttonRef}
      />
    </motion.div>
  );
};
