import * as Factory from "factory.ts";
import { configurationFactory } from "./configuration";
import { userFactory } from "./user";
import { AppContextValue, IntegrationMode } from "@src/types";
import { apiFactory } from "./api";
import { busFactory } from "./bus";

const noop = () => {};

export const appContextFactory = Factory.Sync.makeFactory<AppContextValue>({
  api: apiFactory.build() as unknown as AppContextValue["api"],
  apiOrigin: "",
  appReady: true,
  browserSupportsWebAuthn: true,
  bus: busFactory.build(),
  clearToasts: noop,
  configuration: configurationFactory.build(),
  hasContextError: false,
  hasPasskey: false,
  isEditingAmount: false,
  mode: IntegrationMode.EMBEDDED,
  quoteLimitReached: false,
  setHasPasskey: noop,
  engageAmountEditor: noop,
  closeAmountEditor: noop,
  setQuoteLimitReached: noop,
  setFiatInstrument: noop,
  setTransfer: noop,
  toasterId: 1,
  updateConfiguration: noop,
  updateSession: noop,
  updateUser: noop,
  user: userFactory.build() as unknown as AppContextValue["user"],
});
