import {
  InitializeOnboardingOptions,
  LoginLandingView,
  UserStatus,
} from "@src/types";
import { AnimatePresence, motion } from "framer-motion";
import { AppContext } from "@src/contexts/AppContextProvider";
import { LandingSheetLoginEntryEmail } from "./LandingSheetLoginEntryEmail";
import { LandingSheetLoginEntryPasskey } from "./LandingSheetLoginEntryPasskey";
import { LandingSheetLoginEntryPassword } from "./LandingSheetLoginEntryPassword";
import { LoginFragment } from "@src/generated/sdk";
import { nextAuthStep, getOnboardingEntrypoint } from "@src/utils/authFlow";
import { toast } from "sonner";
import { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePrevious } from "@uidotdev/usehooks";
import { motionVariants } from "@src/utils/animation";
import { usePasskey } from "@src/hooks/usePasskey";
import { ThemeName, ErrorMessages, CustomToastError } from "@tigris/mesokit";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { isOnboardingAppRoute } from "@src/utils/routeHelpers";

const TOAST_ID = "LandingSheetLoginEntry";

/**
 * Present UI for the user to login with email/password.
 */
export const LandingSheetLoginEntry = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    api: { resolveLoginWithEmailAndPassword, resolveAddWallet, resolveUser },
    session,
    updateUser,
    user,
    configuration: { transferKind, walletAddress },
    hasPasskey,
    browserSupportsWebAuthn,
  } = useContext(AppContext);
  const [activeView, setActiveView] = useState<LoginLandingView>(
    session && session.passkeysEnabled && browserSupportsWebAuthn && hasPasskey
      ? "passkey"
      : "email",
  );
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const previousActiveView = usePrevious(activeView);
  const { loginWithPasskey } = usePasskey();
  const { initializeOnboarding } = useOnboarding();

  const resetView = useCallback(() => {
    setActiveView(() =>
      session &&
      session.passkeysEnabled &&
      browserSupportsWebAuthn &&
      hasPasskey
        ? "passkey"
        : "email",
    );
    setEmail("");
    setIsLoading(false);
  }, [browserSupportsWebAuthn, hasPasskey, session]);

  const onSignupRequest = useCallback(
    () => initializeOnboarding(),
    [initializeOnboarding],
  );

  const handleSuccessfulLoginResult = useCallback(
    (loginResult: LoginFragment) => {
      const { profileStatus, userStatus, needsTwoFactor } = loginResult;

      const post2faOnboardingRoute = getOnboardingEntrypoint(
        profileStatus,
        search,
      );

      if (userStatus !== user.status) {
        updateUser({ status: userStatus });
      }

      const toResult = nextAuthStep({
        needsTwoFactor,
        profileStatus,
        userStatus: userStatus as UserStatus,
        transferKind,
        search,
      });

      if (toResult.isErr()) {
        toast.error(toResult.error, { id: TOAST_ID });
        setIsLoading(false);
        return;
      }

      if (isOnboardingAppRoute(toResult.value.to.pathname)) {
        const initializeOnboardingOptions: InitializeOnboardingOptions = {
          initialPathname: toResult.value.to.pathname,
          onReturnToTransferLogin: resetView,
        };

        if (post2faOnboardingRoute.isOk()) {
          initializeOnboardingOptions.post2faOnboardingRoute =
            post2faOnboardingRoute.value.to.pathname;
        }

        // If 2FA, navigate to TransferSheet2Fa with a follow up to the onboarding view
        initializeOnboarding(initializeOnboardingOptions);
      } else {
        // Using `replace` ensures this view is removed from the history stack
        navigate(toResult.value.to, {
          ...toResult.value.options,
          replace: true,
        });
      }
    },
    [
      search,
      user.status,
      transferKind,
      updateUser,
      initializeOnboarding,
      resetView,
      navigate,
    ],
  );

  const doPasswordLogin = useCallback(
    async (password: string) => {
      setIsLoading(true);
      toast.dismiss(TOAST_ID);

      const loginResult = await resolveLoginWithEmailAndPassword({
        input: {
          email,
          password,
          riskSessionKey: session?.riskSession.sessionKey ?? "",
        },
      });

      if (loginResult.isErr()) {
        toast.error(
          <CustomToastError
            title="Login failed"
            body={
              <div>
                <div>Check your email and password.</div>
                <div>
                  If you are a new user,{" "}
                  <a
                    href="#"
                    data-testid={`${TOAST_ID}:forgotPasswordLink`}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      onSignupRequest();
                    }}
                    className="underline opacity-80 transition-opacity hover:opacity-100"
                  >
                    sign up
                  </a>
                  .
                </div>
              </div>
            }
          />,
          { id: TOAST_ID, duration: 90e3 },
        );

        setIsLoading(false);
        return;
      }

      handleSuccessfulLoginResult(loginResult.value);
    },
    [
      email,
      handleSuccessfulLoginResult,
      onSignupRequest,
      resolveLoginWithEmailAndPassword,
      session?.riskSession.sessionKey,
    ],
  );

  const doPasskeyLogin = useCallback(async () => {
    setIsLoading(true);
    toast.dismiss(TOAST_ID);

    const passkeyLoginResult = await loginWithPasskey();
    if (passkeyLoginResult.isErr()) {
      toast.error(passkeyLoginResult.error, { id: TOAST_ID });
      setIsLoading(false);
      setActiveView("email");
      return;
    }

    // Get user details
    const resolveUserResult = await resolveUser();

    if (resolveUserResult.isErr()) {
      toast.error(resolveUserResult.error, { id: TOAST_ID });
      setIsLoading(false);

      return;
    }

    const user = resolveUserResult.value;
    const { walletInstruments, fiatInstruments, depositAddressInstruments } =
      user;

    if (
      fiatInstruments?.__typename === "FiatInstruments" &&
      walletInstruments?.__typename === "WalletInstruments" &&
      depositAddressInstruments?.__typename === "DepositAddressInstruments"
    ) {
      updateUser({
        ...user,
        theme: user.theme as ThemeName,
        fiatInstruments,
        walletInstruments,
        depositAddressInstruments,
      });

      if (walletInstruments?.__typename === "WalletInstruments") {
        // If the current wallet is not associated with the user, but the passkey login was successful, add the wallet to the user
        const walletExistsForUser = walletInstruments?.collection.some(
          ({ address }) =>
            address.toLowerCase() === walletAddress.toLowerCase(),
        );

        if (!walletExistsForUser) {
          // Add it to the user via the API
          const addWalletResult = await resolveAddWallet({
            input: {
              walletAddress,
            },
          });

          if (addWalletResult.isErr()) {
            toast.error(addWalletResult.error, { id: TOAST_ID });
            setIsLoading(false);
            return;
          }

          const user = addWalletResult.value.user;

          if (
            user?.__typename === "User" &&
            user.walletInstruments?.__typename === "WalletInstruments"
          ) {
            updateUser({ walletInstruments: user.walletInstruments });
          } else {
            toast.error(ErrorMessages.common.LOOKUP_USER_API_ERROR, {
              id: TOAST_ID,
            });
            return;
          }
        }
      }
    }

    handleSuccessfulLoginResult(passkeyLoginResult.value);
    return;
  }, [
    handleSuccessfulLoginResult,
    loginWithPasskey,
    resolveAddWallet,
    resolveUser,
    updateUser,
    walletAddress,
  ]);

  return (
    <motion.div
      key="LoginEntryFooter"
      variants={motionVariants.fadeInDown}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <AnimatePresence mode="wait">
        {activeView === "passkey" && (
          <LandingSheetLoginEntryPasskey
            key="LandingSheetLoginEntryAnimation:passkey"
            onLogInWithPasskey={doPasskeyLogin}
            isLoading={isLoading}
          />
        )}

        {activeView === "email" && (
          <LandingSheetLoginEntryEmail
            previousEmailAddress={email}
            key="LandingSheetLoginEntryAnimation:email"
            onComplete={(email: string) => {
              setEmail(email);
              setActiveView("password");
            }}
            from={previousActiveView}
            onSignupRequest={onSignupRequest}
            allowSignUp={!session?.isReturningUser}
            onLogInWithPasskey={doPasskeyLogin}
            isLoading={isLoading}
          />
        )}

        {activeView === "password" && (
          <LandingSheetLoginEntryPassword
            email={email}
            key="LandingSheetLoginEntryAnimation:password"
            isLoading={isLoading}
            onComplete={doPasswordLogin}
            onReturnToEmail={() => setActiveView("email")}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};
